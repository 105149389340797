import { useRef, useState } from "react";
import cn from 'classnames'

import styles from './header.module.scss';
import { Link, NavLink } from "react-router-dom";
import { menu } from "../../const";
import { logo } from "../../assets";
import Button from "../../ui/Button";


const Header = () => {
    const burgerRef = useRef(null);
    const [isOpened, setIsOpened] = useState(false);

    const handleBurgerClick = (e) => {
        if (isOpened) {
            setIsOpened(false)
            document.documentElement.removeAttribute("style");
            return;
        }
        setIsOpened(true)
        document.documentElement.setAttribute("style", "overflow: hidden");
    };

    const handleLinkClick = () => {
        setIsOpened(false)
        document.documentElement.removeAttribute("style");
    }

    return (
        <header className={styles.header}>
            <div className="container">
                <div className={styles['header__wrap']}>
                    <div className={styles.header_brand}>
                        <Link to='/'>
                            <img src={logo} alt="Logo" loading="lazy" />
                        </Link>
                    </div>
                    <nav>
                        <div
                            ref={burgerRef}
                            className={`${styles.burger_bar_container} ${cn({
                                [styles.active]: isOpened
                            })
                                }`}
                            onClick={handleBurgerClick}
                        >
                            <div className={`${styles.bars} ${styles.bar1}`}></div>
                            <div className={`${styles.bars} ${styles.bar2}`}></div>
                            <div className={`${styles.bars} ${styles.bar3}`}></div>
                        </div>
                        <div
                            className={styles.header_content}
                        >
                            <ul>
                                {menu.map((item, index) => {
                                    return (
                                        <li key={index} className={styles['nav-item']}>
                                            <NavLink
                                                onClick={handleLinkClick}
                                                className={((navData) => cn(styles['nav-link'], { [styles['nav-link_active']]: navData.isActive }))}
                                                to={item.path}
                                            >
                                                {item.name}
                                            </NavLink>
                                        </li>
                                    )
                                })}
                                <li><Button link href="#footer">Get In Touch</Button></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    )
};

export default Header;