import { dafoe, gameModels, C93 } from "./assets"

export const VARIANTS = {
    code: 'code',
    art: 'art',
}

export const ICONS = {
    rhombus: 'rhombus',
    circle: 'circle',
}

export const menu = [
    { name: 'Company', path: '/team' },
    { name: 'Services', path: '/services' },
]

export const PAGES = {
    team: 'team',
    services: 'services',
}

export const EMAILJS = {
    publicKey: 'IElgmUQWgyMLPgzld',
    serviceId: 'service_iz3caoc',
    templateId: 'template_3yeo8kj',
}

export const ANIMATION = {
    fade: 'fade',
    left: 'left',
    right: 'right',
    bottom: 'bottom',
    top: 'top',
}

export const codeTypes = [
    'GAMING',
    'WEB3',
    'NFT',
    '2D',
    '3D',
]

export const advantages = [
    'Expertise and specialization',
    'Resources and scalability',
    'Creativity and innovation',
    'Cost-effectiveness',
]

export const POSITIONS = {
    topLeft: 'top-left',
    topRight: 'top-right',
    bottomRight: 'bottom-right',
}

export const statistics = [
    { id: 1, name: 'NFT PROJECTS', value: "100+", position: POSITIONS.bottomRight },
    { id: 2, name: 'NFTs', value: "500K", position: POSITIONS.topLeft },
    { id: 3, name: 'VOLUME TRADED', value: "30K", position: POSITIONS.topRight },
]

export const gamesArtItems = [
    { id: 1, title: 'Game Models', description: '3D figurines made for 3D printing', imageSrc: gameModels },
    { id: 2, title: 'Willem Dafoe', description: 'Realistic 3D visualizations of people', imageSrc: dafoe },
    { id: 3, title: 'The Borchardt C93', description: 'Hard Surface modeling of German pistol', imageSrc: C93 },
]

export const web3Info = [
    'SMART CONTRACT',
    'MARKETPLACE',
    'METAVERSE',
]