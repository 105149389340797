import cn from 'classnames'
import { StarIcon } from '../../assets'
import { PAGES } from '../../const'
import styles from './intro.module.scss'

const Intro = ({ title, children, variant }) => {
  return (
    <section className={cn(styles['intro'], {
      [styles['intro_team']]: variant === PAGES.team,
      [styles['intro_services']]: variant === PAGES.services,
    })
    }>
      <div className="container">{children}</div>
      <div className={styles['intro__icon']}>
        <StarIcon />
      </div>
    </section>
  )
}

export default Intro
