import "@fortawesome/fontawesome-free/js/all";
import { Route, Routes } from "react-router-dom";
import "swiper/css";
import Layout from "./layout";
import Home from "./pages/home";
import Services from "./pages/services";
import Team from "./pages/team";
import "./styles/globals.scss";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/team"
        element={
          <Layout>
            <Team />
          </Layout>
        }
      />
        <Route
          path="/services"
          element={
            <Layout>
              <Services />
            </Layout>
          }
        />
 
    </Routes>
  );
}

export default App;
