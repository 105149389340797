import emailjs from "@emailjs/browser";
import cn from "classnames";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { StarIcon } from "../../assets";
import { EMAILJS, ANIMATION } from "../../const";
import { schema } from "../../schema";
import Animation from "../Animation";
import Loader from "../Loader";
import styles from "./form.module.scss";
import Button from '../../ui/Button'

const Form = () => {
  useEffect(() => {
    emailjs.init(EMAILJS.publicKey);
  }, []);

  const [emailStatus, setEmailStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    name: "",
    subject: "",
    msg: "",
    isAgree: "",
  };

  const handleSubmit = async (data, { resetForm }) => {
    setLoading(true);
    try {
      await emailjs.send(EMAILJS.serviceId, EMAILJS.templateId, data);
      resetForm({});
      setEmailStatus(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <form className={styles["form"]}>
      <StarIcon className={styles['form__star']} />
      <div className={styles["form__group_flex"]}>
        <div className={styles["form__group"]}>
          <label className={styles["form__label"]} htmlFor="name">Full Name</label>
          <input
            id="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            name="name"
            className={cn(styles["form__input"])}
            type="text"
          />
        </div>
        <div className={styles["form__group"]}>
          <label className={styles["form__label"]} htmlFor="email">Email</label>
          <input
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            name="email"
            className={cn(styles["form__input"], {
              [styles["form__input_error"]]: formik.errors.email,
            })}

            type="text"
          />
          <p className={styles["form__error-text"]}>{formik.errors.email}</p>
        </div>
      </div>
      <div className={styles["form__group"]}>
        <label className={styles["form__label"]} >What services are you interested in?</label>
        <select
          onChange={(e) => formik.setFieldValue("subject", e.target.value)}
          className={styles["form__select"]}
          name=""
          id=""
        >
          <option value="web3">Web3</option>
          <option value="Game Art">Game Art</option>
          <option value="Game Animation">
          Game Animation
          </option>
          <option value="Digital Services">
          Digital Services
          </option>
          <option value="3d website">3d Website</option>
          <option value="NFT">NFT</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className={styles["form__group"]}>
      <label className={styles["form__label"]} >Message</label>
        <textarea
          rows={1}
          onChange={formik.handleChange}
          name="msg"
          className={styles["form__msg"]}
          value={formik.values.msg}
        />
      </div>
      <div className={cn(styles["form__group"], 'mb-2')}>
        <label className={styles["form__terms"]}>
          <input
            onChange={(e) =>
              formik.setFieldValue("isAgree", e.target.checked)
            }
            className={cn(styles["form__terms-input"])}
            type="checkbox"
            checked={formik.values.isAgree}
          />
          <span
            className={cn(styles["form__checkmark"], {
              [styles["form__checkmark_error"]]: formik.errors.isAgree,
            })}
          />
          I have read and accepted the Terms and Conditions and Privacy Policy
        </label>
      </div>
      {emailStatus && (
        <div className={styles["form__group"]}>
          <p className={styles["form__success-text"]}>
            We have received your message and would like to thank you for
            writing to us.
          </p>
        </div>
      )}
      
      <Button
        onClick={formik.handleSubmit}
        className={cn(styles["form__send-btn"], {
          [styles["form__send-btn_loading"]]: loading,
        })}
        type="button"
      >
        {loading ? <Loader /> : "Contact Us"}
      </Button>
    </form>
  );
};

export default Form;
