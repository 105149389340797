import { slider1, slider10, slider12, slider14, slider15, slider16, slider17, slider18, slider19, slider2, slider20, slider21, slider22, slider23, slider24, slider25, slider26, slider27, slider28, slider29, slider3, slider30, slider31, slider32, slider33, slider34, slider35, slider36, slider37, slider38, slider39, slider4, slider40, slider41, slider42, slider43, slider44, slider45, slider46, slider47, slider48, slider49, slider5, slider50, slider51, slider52, slider6, slider7, slider8, slider9 } from "../assets";

const fakeCollectionData = [
  {
    id: "1",
    imageSrc: slider6,
    title: "Gambling Apes",
    date: "Upcoming",
    imageWidth: 300,
    imageHeight: 300,
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://gamblingapes.com",
        icon: 'fas fa-external-link-alt'
      },
      {
        src: "https://twitter.com/gamblingapes",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://discord.gg/gamblingapes",
        icon: 'fab fa-discord'
      }
    ],
  },
  {
    id: "2",
    imageSrc: slider18,
    title: "Angry Apes United",
    date: "",
    imageWidth: 300,
    imageHeight: 300,
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://www.angryapesunited.com/",
        icon: 'fas fa-external-link-alt'
      },
      {
        src: "https://twitter.com/angryapesunited",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://discord.gg/nR7S3tDZRD",
        icon: 'fab fa-discord'
      }
    ],
  },
  // {
  //   id: "3",
  //   imageSrc: slider30,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Fashion Ape NFT",
  //   date: "",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: [
  //     {
  //       src: "https://discord.gg/kJYzBh9jVV",
  //       icon: 'fab fa-discord'
  //     },
  //     {
  //       src: "http://twitter.com/astrobabiesnft",
  //       icon: 'fab fa-twitter'
  //     },
  //     {
  //       src: "https://www.astrobabies.io/",
  //       icon: 'fas fa-external-link-alt'
  //     }
  //   ],
  // },
  {
    id: "4",
    imageSrc: slider28,
    imageWidth: 300,
    imageHeight: 300,
    title: "Perilous Petz",
    date: "",
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://discord.gg/kJYzBh9jVV",
        icon: 'fab fa-discord'
      },
      {
        src: "http://twitter.com/astrobabiesnft",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://www.astrobabies.io/",
        icon: 'fas fa-external-link-alt'
      }
    ],
  },
  {
    id: "5",
    imageSrc: slider29,
    imageWidth: 300,
    imageHeight: 300,
    title: "Party Grandpa | Retirement Club",
    date: "",
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://discord.gg/kJYzBh9jVV",
        icon: 'fab fa-discord'
      },
      {
        src: "http://twitter.com/astrobabiesnft",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://www.astrobabies.io/",
        icon: 'fas fa-external-link-alt'
      }
    ],
  },
  {
    id: "6",
    imageSrc: slider27,
    imageWidth: 300,
    imageHeight: 300,
    title: "Retirement Club Babies",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://corporatecryptoclub.com/",
        icon: 'fas fa-external-link-alt'
      },
      {
        src: "https://twitter.com/CorpCryptoClub",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://discord.gg/W5r3bFsjKw",
        icon: 'fab fa-discord'
      }
    ],
  },

  // {
  //   id: "7",
  //   imageSrc: slider36,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Mutant Ape Planet",
  //   date: "",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: [
  //     {
  //       src: "https://discord.gg/kJYzBh9jVV",
  //       icon: 'fab fa-discord'
  //     },
  //     {
  //       src: "http://twitter.com/astrobabiesnft",
  //       icon: 'fab fa-twitter'
  //     },
  //     {
  //       src: "https://www.astrobabies.io/",
  //       icon: 'fas fa-external-link-alt'
  //     }
  //   ],
  // },
  {
    id: "8",
    imageSrc: slider31,
    imageWidth: 300,
    imageHeight: 300,
    title: "Jungle Destroyers",
    date: "",
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://discord.gg/kJYzBh9jVV",
        icon: 'fab fa-discord'
      },
      {
        src: "http://twitter.com/astrobabiesnft",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://www.astrobabies.io/",
        icon: 'fas fa-external-link-alt'
      }
    ],
  },
  {
    id: "9",
    imageSrc: slider32,
    imageWidth: 300,
    imageHeight: 300,
    title: "Meta Sneakers Genesis",
    date: "",
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://discord.gg/kJYzBh9jVV",
        icon: 'fab fa-discord'
      },
      {
        src: "http://twitter.com/astrobabiesnft",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://www.astrobabies.io/",
        icon: 'fas fa-external-link-alt'
      }
    ],
  },
  {
    id: "10",
    imageSrc: slider2,
    imageWidth: 300,
    imageHeight: 300,
    title: "OG Astro babies Solana",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://corporatecryptoclub.com/",
        icon: 'fas fa-external-link-alt'
      },
      {
        src: "https://twitter.com/CorpCryptoClub",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://discord.gg/W5r3bFsjKw",
        icon: 'fab fa-discord'
      }
    ],
  },
  {
    id: "11",
    imageSrc: slider19,
    imageWidth: 300,
    imageHeight: 300,
    title: "Astro Babies Apes Solana",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: []
  },
  {
    id: "12",
    imageSrc: slider33,
    imageWidth: 300,
    imageHeight: 300,
    title: "Astro Pups Solana",
    date: "",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "13",
    imageSrc: slider34,
    imageWidth: 300,
    imageHeight: 300,
    title: "Degenerate Granny | Retirement Club",
    date: "",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "14",
    imageSrc: slider35,
    imageWidth: 300,
    imageHeight: 300,
    title: "The Crypto Paradox Club",
    date: "",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "15",
    imageSrc: slider15,
    title: "The Awakening",
    imageWidth: 300,
    imageHeight: 300,
    date: "Upcoming",
    description:
      "3D art concept and traits, generation of the collection, smart contract, minting website",
    socials: [
      {
        src: "https://discord.gg/YfETuPXmTH",
        icon: 'fab fa-discord'
      },
      {
        src: "https://twitter.com/TheAwakeningNFT",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://www.instagram.com/theawakeningnft/",
        icon: 'fab fa-instagram'
      }
    ],
  },
  {
    id: "16",
    imageSrc: slider16,
    imageWidth: 300,
    imageHeight: 300,
    title: "Invokers",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://discord.gg/invokers",
        icon: 'fab fa-discord'
      }
    ],
  },
  {
    id: "17",
    imageSrc: slider1,
    title: "Wall street dropouts",
    date: "",
    description: "Art concept and traits, generation of the collection",
    imageWidth: 300,
    imageHeight: 300,
    socials: [],
  },

  {
    id: "18",
    imageSrc: slider21,
    imageWidth: 300,
    imageHeight: 300,
    title: "Corporate Crypto Club",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [
      {
        src: "https://corporatecryptoclub.com/",
        icon: 'fas fa-external-link-alt'
      },
      {
        src: "https://twitter.com/CorpCryptoClub",
        icon: 'fab fa-twitter'
      },
      {
        src: "https://discord.gg/W5r3bFsjKw",
        icon: 'fab fa-discord'
      }
    ],
  },
  {
    id: "19",
    imageSrc: slider37,
    imageWidth: 300,
    imageHeight: 300,
    title: "Godess Iris",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "20",
    imageSrc: slider38,
    imageWidth: 300,
    imageHeight: 300,
    title: "SUWC-Swiss Unique Watch Club",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "21",
    imageSrc: slider39,
    imageWidth: 300,
    imageHeight: 300,
    title: "Flokimooni",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "22",
    imageSrc: slider40,
    imageWidth: 300,
    imageHeight: 300,
    title: "Mystical Muses NFT",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "23",
    imageSrc: slider23,
    imageWidth: 300,
    imageHeight: 300,
    title: "Boss Chicks",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "24",
    imageSrc: slider41,
    imageWidth: 300,
    imageHeight: 300,
    title: "Crypto Crewz",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "25",
    imageSrc: slider14,
    imageWidth: 300,
    imageHeight: 300,
    title: "Crypto Hippos",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: []
  },
  {
    id: "26",
    imageSrc: slider42,
    imageWidth: 300,
    imageHeight: 300,
    title: "Calabasas Cave Creatures",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: []
  },
  {
    id: "27",
    imageSrc: slider43,
    imageWidth: 300,
    imageHeight: 300,
    title: "The Blind Club",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: []
  },
  {
    id: "28",
    imageSrc: slider10,
    imageWidth: 300,
    imageHeight: 300,
    title: "Dangerous Dinos",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "29",
    imageSrc: slider44,
    imageWidth: 300,
    imageHeight: 300,
    title: "Modoverse",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "30",
    imageSrc: slider45,
    imageWidth: 300,
    imageHeight: 300,
    title: "Cryptolex NFT",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },

  {
    id: "31",
    imageSrc: slider25,
    imageWidth: 300,
    imageHeight: 300,
    title: "Squirrels FLow",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },

  {
    id: "32",
    imageSrc: slider22,
    imageWidth: 300,
    imageHeight: 300,
    title: "Dabookie",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "33",
    imageSrc: slider46,
    imageWidth: 300,
    imageHeight: 300,
    title: "Crypto Polar Bear Club",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "34",
    imageSrc: slider47,
    imageWidth: 300,
    imageHeight: 300,
    title: "Space Samurai",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "35",
    imageSrc: slider48,
    imageWidth: 300,
    imageHeight: 300,
    title: "AristoJag",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },

  {
    id: "36",
    imageSrc: slider8,
    title: "Racoonverse",
    date: "Upcoming",
    imageWidth: 300,
    imageHeight: 300,
    description:
      "Pix art concept and traits, generation of the collection, smart contract, minting website, discord community management",
    socials: [],
  },
  {
    id: "37",
    imageSrc: slider49,
    title: "Rebel Tiger Club",
    date: "Upcoming",
    imageWidth: 300,
    imageHeight: 300,
    description:
      "Pix art concept and traits, generation of the collection, smart contract, minting website, discord community management",
    socials: [],
  },
  {
    id: "38",
    imageSrc: slider50,
    title: "Kangaroo Fight Club",
    date: "Upcoming",
    imageWidth: 300,
    imageHeight: 300,
    description:
      "Pix art concept and traits, generation of the collection, smart contract, minting website, discord community management",
    socials: [],
  },
  {
    id: "39",
    imageSrc: slider51,
    title: "Football Ape",
    date: "Upcoming",
    imageWidth: 300,
    imageHeight: 300,
    description:
      "Pix art concept and traits, generation of the collection, smart contract, minting website, discord community management",
    socials: [],
  },
  {
    id: "40",
    imageSrc: slider26,
    imageWidth: 300,
    imageHeight: 300,
    title: "Ninja Clan NFT",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },
  {
    id: "41",
    imageSrc: slider52,
    imageWidth: 300,
    imageHeight: 300,
    title: "Gifted Tigers NFT",
    date: "Upcoming",
    description: "Art concept and traits, generation of the collection",
    socials: [],
  },

  // {
  //   id: "4",
  //   imageSrc: slider4,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Lambs in Lamborghini",
  //   date: "Upcoming",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: []
  // },
  // {
  //   id: "5",
  //   imageSrc: slider5,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Rich Jaguar",
  //   date: "Upcoming",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: []
  // },
  // {
  //   id: "7",
  //   imageSrc: slider7,
  //   title: "Mad Tesla",
  //   date: "Upcoming",
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   description: "Art concept and traits, generation of the collection",
  //   socials: [
  //     {
  //       src: "https://discord.gg/rcdRdr3q8N",
  //       icon: 'fab fa-discord'
  //     }
  //   ],
  // },
  // {
  //   id: "3",
  //   imageSrc: slider3,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Crypto Crewz",
  //   date: "Upcoming",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: [],
  // },
  // {
  //   id: "9",
  //   imageSrc: slider9,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Armed Bears",
  //   date: "Upcoming",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: []
  // },

  // {
  //   id: "12",
  //   imageSrc: slider12,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "SUW Club",
  //   date: "Upcoming",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: []
  // },
  // {
  //   id: "17",
  //   imageSrc: slider17,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Witchy Women Coven",
  //   date: "Upcoming",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: []
  // },
  // {
  //   id: "20",
  //   imageSrc: slider20,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Octo-Friends",
  //   date: "Upcoming",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: []
  // },




  // {
  //   id: "24",
  //   imageSrc: slider24,
  //   imageWidth: 300,
  //   imageHeight: 300,
  //   title: "Slider Tigers",
  //   date: "Upcoming",
  //   description: "Art concept and traits, generation of the collection",
  //   socials: [
  //     {
  //       src: "https://corporatecryptoclub.com/",
  //       icon: 'fas fa-external-link-alt'
  //     },
  //     {
  //       src: "https://twitter.com/CorpCryptoClub",
  //       icon: 'fab fa-twitter'
  //     },
  //     {
  //       src: "https://discord.gg/W5r3bFsjKw",
  //       icon: 'fab fa-discord'
  //     }
  //   ],
  // },

];

export default fakeCollectionData;
