import cn from 'classnames'
import { useLocation } from 'react-router'
import { logoBlack } from '../../assets'
import { menu, ANIMATION } from '../../const'
import Animation from '../Animation'
import Form from '../Form'
import styles from './footer.module.scss'

const Footer = () => {
  const { pathname } = useLocation()

  return (
    <footer id='footer' className={styles.footer}>
      <div className="container">
        <div className={
          cn(styles['footer__wrap'], {
            [styles['footer__wrap_bg_2']]: pathname === menu[0].path,
            [styles['footer__wrap_bg_3']]: pathname === menu[1].path
          })}>
          <div className={styles['footer__form']}>
            <div className={styles['footer__form-text']}>
              <h3 className={styles['footer__form-title']}> LET’S TALK ABOUT YOUR PROJECT</h3>
              <p className={styles['footer__form-desc']}>
                We are ready to discuss the details of your project and answer all
                of your questions.
              </p>
            </div>
            <Animation variant={ANIMATION.right}>
            <Form />
            </Animation>
          </div>
          <div className={styles.brand}>
            <a
              href="mailto:partners@oddrepublic.io"
              target="_blank"
              rel="noreferrer"
              className={styles.brand__link}
            >
              <img alt="Logo" src={logoBlack} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
