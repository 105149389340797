import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { ANIMATION } from "../../const";
import styles from "./index.module.scss";

const Animation = ({ children, offset = 120, variant = ANIMATION.fade }) => {
  const divRef = useRef();
  const [isShow, SetIsShow] = useState(false);

  const scrollHandler = () => {
    if (
      divRef.current.getBoundingClientRect().top <=
      (window.innerHeight || document.documentElement.clientHeight) - offset
    ) {
      SetIsShow(true);
    } else {
        SetIsShow(false);
    }
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("scroll", scrollHandler, true);
    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);

  return (
    <div className={styles[`an`]}>
      <div
        className={cn(styles[`an_${variant}`], {
          [styles[`an_show_${variant}`]]: isShow,
        })}
        ref={divRef}
      >
        {children}
      </div>
    </div>
  );
};

export default Animation;
