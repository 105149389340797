import Helmet from 'react-helmet'
import Animation from '../components/Animation'
import Intro from '../components/Intro'
import { ANIMATION, PAGES } from '../const'
import styles from '../styles/team.module.scss'
import Button from '../ui/Button'
import team from '../utils/fakeData'

const Team = () => {
  return (
    <>
      <Helmet>
        <title>GSD CLUB Team</title>
        <meta
          name="description"
          content="Still hesitating about the art? Check out the NFTs and 3D/2D arts created by our artists"
        />
      </Helmet>
      <Intro variant={PAGES.team}>
        <div className={styles['intro__wrap']}>
          <div>
            <h1>OUR LEADERSHIP TEAM</h1>
            <p className={styles['intro__desc']}>
              GSD.Club is a leading gaming&web3 outsourcing company that lends
              its strong professional shoulder to the world’s great projects.
              Thanks to an experienced leadership team united by a common vision
              and a desire to conquer new heights, the company is constantly
              developing and improving.
            </p>
          </div>
        </div>
      </Intro>
      <section className={styles['leaders']}>
        <div className="container">
          <h2 className={styles['leaders__title']}>MEET GSD CLUB Leaders</h2>
          <p className={styles['leaders__desc']}>
            Our forward-thinking leadership team is made up of dedicated,
            motivated and experienced executives supervising and polishing GSD
            Club’ professional services.
          </p>
          <div className={styles['team']}>
            {team.map((item, idx) => (
              <div className={styles['team__item']} key={idx}>
                <div className={styles['team__item-img']}>
                  <Animation variant={ANIMATION.fade}><img src={item.img} alt={item.name} /></Animation>
                </div>{' '}
                <div className={styles['team__info']}>
                  <div className={styles['team__info-text']}>
                    <div>
                      <h3>{item.name}</h3>
                      <p>{item.position}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={styles['join']}>
        <div className="container">
          <h2 className={styles['join__title']}>WANT TO JOIN OUR TEAM?</h2>
          <Animation variant={ANIMATION.bottom}>
            <p className={styles['join__desc']}>
              We are constantly on the lookout for talented and inspired
              professionals. If you share our values and want to develop with us,
              do not hesitate to go to the hot vacancies section and send us your
              resume.
            </p>
            <Button 
            link 
            href="https://www.linkedin.com/company/gsd-club-agency/" 
            target="_blank"
            className={styles['join__btn']}>View Vacancies</Button>
          </Animation>
        </div>
      </section>
    </>
  )
}

export default Team
