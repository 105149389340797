import { useState, useCallback } from "react";
import Cookies from "js-cookie";
import { goblin } from "../../assets";
import styles from "./index.module.scss";

const COOKIE_KEY = "banner_hidden";

const GoblinBanner = () => {
  const [isShown, setIsShown] = useState(!Cookies.get(COOKIE_KEY));

  const handleHideBanner = useCallback(() => {
    setIsShown(false);
    Cookies.set(COOKIE_KEY, true);
  }, []);

  if (!isShown) return null;

  return (
    <div className={styles.container}>
      <button className={styles.close} onClick={handleHideBanner}>
        &times;
      </button>
      <a target="_blank" href="https://unrealgoblins.club/">
        <img src={goblin} alt="unreal goblins" />
        <span>
          OUR First <span>3D Animated</span> NFT Collection fully generated and
          rendered on <span>Unreal Engine</span>
        </span>
      </a>
    </div>
  );
};

export default GoblinBanner;
