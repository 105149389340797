import { default as cn } from 'classnames'
import { useEffect, useRef } from 'react'
import Helmet from 'react-helmet'
import Swiper, { Navigation } from 'swiper'
import {
  metaverse,
  squirrel,
  StarIcon
} from '../assets'
import Animation from '../components/Animation'
import Intro from '../components/Intro'
import Slider from '../components/Slider'
import Testominals from '../components/Testominals'
import {
  advantages, codeTypes,
  gamesArtItems, statistics,
  web3Info,
  ANIMATION,
} from '../const'
import styles from '../styles/home.module.scss'
import Button from '../ui/Button'
import fakeCollectionData from '../utils/fakeCollectionData'

Swiper.use([Navigation])

function Home() {
  const sliderRef = useRef(null)

  useEffect(() => {
    sliderRef.current = new Swiper('.home--slider', {
      slidesPerView: 1,
      allowTouchMove: true,
      draggable: true,
      on: {
        slideChange: function (swiper) {
          swiper.updateSize()
        },
      },
      breakpoints: {
        992: {
          slidesPerView: 'auto',
          allowTouchMove: false,
          draggable: false,
          slideToClickedSlide: true,
          centeredSlides: true,
          loop: true,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>GSD CLUB</title>
        <meta
          name="description"
          content="GSD.Club is a full service provider for NFT collection development."
        />
      </Helmet>
      <Intro>
        <div className={styles['intro__wrap']}>
          <div>
            <h1>YOUR GAMING&WEB3 OUTSOURCING AGENCY</h1>
            <p className={styles['intro__desc']}>
              Gsd Club is covering art and development of programmatically
              generated NFT collections. The team is formed of 2D, 3D modelers,
              animators and blockchain developers. The main focus of our company
              is bringing new possibilities and features to NFT world.
            </p>
            <Button link href="#footer" className={styles['intro__btn']}>Get in Touch</Button>
          </div>
          <img className={styles['intro__img']} src={metaverse} alt="Metaverse" />
        </div>
      </Intro>
      <section className={styles['code']} id="code">
        <div className="container">
          <div className={styles['code__wrap']}>
            <div className={styles['code__desc-wrap']}>
              <div>
                <div className={styles['code__line']}>
                  <StarIcon className={styles['code__line-star']} />
                </div>
                <p className={styles['code__desc']}>
                  We design and develop digital metaverse products and online
                  experiences that help our clients grow, innovate, and
                  transform. We listen, learn and understand before we build.
                  Identifying your goals together, then use our expertise to
                  find that sweet spot of realistic and impactful.
                </p>
              </div>
              <div className={styles['code__logo']}>GSD</div>
            </div>
            <div className={styles['code-types']}>
              {codeTypes.map((item, idx) => (
                <div className={styles['code-types__item']} key={idx}>
                  <div><Animation variant={ANIMATION.top}> {item}</Animation></div>{' '}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className={styles['why']}>
        <div className="container">
          <div className={styles['why__wrap']}>
            <div>
              <h2 className={styles['why__title']}>WHY GSD?</h2>
              <div>
                <div className={styles['why-advantages']}>
                  <StarIcon className={styles['why-advantages__star']} />
                  {advantages.map((text, idx) => (
                    <div className={styles['why-advantages__item']}>
                      <Animation variant={ANIMATION.left}>
                        <div className={styles['why-advantages__num']}>
                          {idx + 1}
                        </div>
                      </Animation>
                      <p>{text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles['why-statistics']}>
              <div className={styles['why-statistics__wrap']}>
                {statistics.map((item) => (
                  <div className={styles['why-statistics__item']} key={item.id}>
                    <StarIcon
                      className={cn(
                        styles[`why-statistics__icon_${item.position}`],
                        styles['why-statistics__icon']
                      )}
                    />
                    <div>
                      <Animation variant={ANIMATION.right}>
                        <p className={styles['why-statistics__value']}>
                          {item.value}
                        </p>
                      </Animation>
                      <Animation>
                        <h3 className={styles['why-statistics__name']}>
                          {item.name}
                        </h3>
                      </Animation>
                    </div>
                  </div>
                ))}
              </div>
              <p>
                It's important for a business or organization to choose the
                right agency to work with, as the agency will play a crucial
                role in helping to achieve the organization's marketing and
                communication goals.{' '}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={styles['testominals']}>
        <div className="container">
          <div className={styles['testominals_bg']}>
            <div className={styles['testominals__wrap']}>
              <div className={styles['testominals__title']}>
                <h2 className="h1">TESTIMONIALS</h2>
                <StarIcon className={styles['testominals__title-icon']} />
              </div>
              <Testominals />
            </div>
            <Animation variant={ANIMATION.right}>
              <p className={styles['testominals__desc']}>
                We are so grateful to our customers for their loyalty and support.
                Without you, we would not be able to do what we love and continue
                to grow and improve. Your feedback and suggestions help us to
                continually improve our products and services, and we truly value
                your business. We are grateful for the trust you place in us, and
                we will continue to work hard to earn and keep that trust. We
                appreciate the opportunity to serve you and to be a part of your
                lives.
              </p>
            </Animation>
          </div>
        </div>
      </section>
      <section className={styles['art']}>
        <div className="container">
          <div className={styles['art__bg']}>
            <div className={styles['art__wrap']}>
              <Animation>
                <StarIcon className={styles['art__star']} />
              </Animation>
              <h2 className={styles['art__title']}>GAME ART</h2>
              <div className={styles['art__desc']}>
                <Animation variant={ANIMATION.right}>
                  <p>
                    Game art refers to the visual elements of a video game, such
                    as characters, environments, and objects. It can include 2D
                    and 3D graphics, as well as concept art, animation, and other
                    visual effects. Game artists are responsible for creating the
                    visual elements of a game, working closely with game designers
                    and developers to bring the game to life.
                  </p>
                  <p>
                    The work we do requires a combination of artistic skills and
                    technical expertise. Game artists typically have a strong
                    foundation in traditional art skills such as drawing,
                    painting, and sculpting, as well as an understanding of 3D
                    modeling and animation software. They may also have knowledge
                    of programming and game engine technology, as well as an
                    understanding of game design principles.
                  </p>
                </Animation>
              </div>
            </div>
          </div>
          <div className={styles['art__slider']}>
            <StarIcon className={styles['art__slider-star']} />
            <Slider sliders={gamesArtItems} />
          </div>
        </div>
      </section>
      <section className={styles['web3']}>
        <div className="container">
          <div className={styles['web3__text']}>
            <StarIcon className={styles['web3__star-l']} />
            <Animation variant={ANIMATION.bottom}>

              <StarIcon className={styles['web3__star-r']} />
            </Animation>
            <h2 className={styles['web3__title']}>WEB3</h2>
            <p className={styles['web3__desc']}>
              Web3 technologies offer the potential to create a more open and
              decentralized internet that is not controlled by a single entity
              or group of entities, but rather is governed by a decentralized
              network of users. This could enable a wide range of new
              applications and use cases, including decentralized finance
              (DeFi), peer-to-peer marketplaces, self-sovereign identity
              systems, and more. These technologies are still in the early
              stages of development, and many challenges remain to be addressed
              in order to realize the full potential of the decentralized web.
              However, there are already many exciting projects and initiatives
              underway that are exploring the use of web3 technologies to create
              a more open and decentralized internet.
            </p>
          </div>
          <div className={styles['web3__wrap']}>
            {web3Info.map((item, idx) => (
              <div className={styles['web3__item']} key={idx}>
                <Animation variant={ANIMATION.left}>
                  <h3>{item}</h3>
                </Animation>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={styles['portfolio']}>
        <div className="container">
          <div className={styles['portfolio__wrap']}>
            <img
              className={styles['portfolio__img']}
              src={squirrel}
              alt="Squirrel"
            />
            <div>
              <div className={styles['portfolio__text']}>
                <StarIcon className={styles['portfolio__star']} />
                <h2 className={styles['portfolio__title']}>NFTs</h2>
                <Animation variant={ANIMATION.right}>
                  <p className={styles['portfolio__desc']}>
                    NFTs can represent a wide range of assets, including digital
                    artwork, collectible items, in-game items, and more. They are
                    often used in the realm of digital art and collectibles, as
                    they allow artists and collectors to authenticate and sell
                    unique digital works.
                  </p>
                  <p className={styles['portfolio__desc']}>
                    One of the main benefits of NFTs is that they allow for the
                    creation of true ownership of digital assets. Unlike
                    traditional digital media, which can be easily copied and
                    distributed, NFTs provide a way for artists and creators to
                    prove ownership and control over their digital creations. This
                    can provide a new revenue stream for artists and creators and
                    allow them to better protect their intellectual property
                  </p>
                </Animation>
              </div>
            </div>
          </div>
          <div className={styles['portfolio__slider']}>
            <StarIcon className={styles['portfolio__slider-star']} />
            <Slider sliders={fakeCollectionData} />
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
