import Helmet from 'react-helmet'
import { StarIcon } from '../assets'
import Animation from '../components/Animation'
import Intro from '../components/Intro'
import { PAGES, ANIMATION } from '../const'
import styles from '../styles/services.module.scss'
import { services } from '../utils/fakeData'

const Services = () => {
  return (
    <>
      <Helmet>
        <title>GSD CLUB Team</title>
        <meta
          name="description"
          content="Still hesitating about the art? Check out the NFTs and 3D/2D arts created by our artists"
        />
      </Helmet>
      <Intro variant={PAGES.services}>
        <div className={styles['intro__wrap']}>
          <div>
            <h1>ALL SERVICES</h1>
            <p className={styles['intro__desc']}>
              Satisfy any of your business needs with our services tailored to
              your goals, budgets, and scope: get your games built from scratch,
              gamify your products, ride a wave of the NFT and the Metaverse, or
              choose any other art, animation, and development service – we
              offer you a result-oriented partnership with seasoned masters of
              their craft.
            </p>
          </div>
        </div>
      </Intro>
      <section className={styles.services}>
        <div className="container">
          <h2 className={styles['services__title']}>Learn more about our expertises</h2>
          <p className={styles['services__desc']}>
            Check out the full list of our services – each department contains
            top-class specialists who can see the essence of the request and
            perform effective results that bring direct benefits to your
            business.
          </p>
          <div className={styles['services__wrap']}>
            {services.map(item => (
              <div className={styles['services__item']} key={item.id}>
                <Animation variant={ANIMATION.fade}>
                  <StarIcon className={styles['services__item-star']} />
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                  <ul>
                    {item.list.map((item, idx) => (
                      <li key={idx}><StarIcon /> {item}</li>
                    ))}
                  </ul>
                </Animation>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Services
