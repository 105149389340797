import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './index.module.scss'

const Slider = ({ sliders }) => {
  return (
    <Swiper
      slidesPerView={1}
      navigation={true}
      className="slider"
      autoHeight={true}
      breakpoints={{
        567: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
    }}
    >
      <div className={styles['slider']}>
        {sliders.map(({ id, title, description, imageSrc }, index) => (
          <SwiperSlide key={id}>
            <div className={styles['slider__card']}>
              <img className={styles['slider__img']} src={imageSrc} alt={title} />
              <h4 className={styles['slider__title']}>{title}</h4>
              <p className={styles['slider__desc']}>{description}</p>
            </div>
          </SwiperSlide>
        ))}
      </div>
    </Swiper>
  )
}

export default Slider
