import cn from 'classnames'
import { StarIcon } from '../../assets'
import styles from './testimonialCard.module.scss'

const TestimonialCard = ({ description, name, position, classNames, img }) => (
  <div className={cn(styles.container, classNames, 'slider-testominals__container')}>
    <div className={styles['card']}>
      <StarIcon className='slider-testominals__star' />
      <p className={styles.description}>{description}</p>
      <div className={styles.wrapper}>
        <div className={styles.image}>
          {img && (
            <img alt={name} src={img} width={80} height={80} loading="lazy" />
          )}
        </div>
        <div>
          <h3 className={styles.name}>{name}</h3>
          <h4 className={styles.position}>{position}</h4>
        </div>
      </div>
    </div>
  </div>
)

export default TestimonialCard
