import { ArmanMamyan, DavidHayrapetyan, DavitMovsisyan, HakobTumanyan, TigranBadalyan } from "../assets";

const team = [
    {
        name: 'David Movsisyan',
        position: 'CEO',
        img: DavitMovsisyan,
    },
    {
        name: 'Arman Mamyan',
        position: 'CTO',
        img: ArmanMamyan,
    },
    {
        name: 'Tigran Badalyan',
        position: 'COO',
        img: TigranBadalyan,
    },
    {
        name: 'David Hayrapetyan',
        position: 'Art-Director',
        img: DavidHayrapetyan,
    },
    {
        name: 'Hakob Tumanyan',
        position: 'CSO',
        img: HakobTumanyan,
    },
];

export const services = [
    {
        id: 1,
        title: 'WEB3',
        desc: `We offer a full range of WEB3 development services for the most popular platforms using all the 
        latest technologies, approaches, and toolsets to implement game projects of any complexity, scale, style, and purpose.`,
        list: ['NFT Game Development', 'NFT Marketplace Development', 'Metaverse Development', 'Blockchain Game Development']
    },
    {
        id: 2,
        title: 'GAME ART',
        desc: `Our artists have experience in creating art for the world's leading gaming companies, skillfully adapt to any technical and aesthetic requirements and create art that meets the highest international standards.`,
        list: ['Concept Art', 'Casual Art', '2D/3D Environment Design', '2D/3D Character Design', 'Hidden Objects', 'Slots Games Art', '3D Sculpting', 'UI/UX Design', 'Illustration Services']
    },
    {
        id: 3,
        title: 'GAME ANIMATION',
        desc: `GSD.Club’s animation team will help bring 2D and 3D characters and environments to life for the most immersive experience for any product, be it a mobile game or a VR simulation.`,
        list: ['2D Animation', '3D Animation', 'Motion Graphic Animation']
    },
    {
        id: 4,
        title: 'DIGITAL SERVICES',
        desc: `We offer a full range of digital services for the most popular platforms using all the latest technologies, approaches, and toolsets to create and implement strategies of any complexity, scale, style, and purpose.`,
        list: ['Marketing Strategy', 'Email Marketing', 'CRO', 'CRM']
    },
    {
        id: 5,
        title: '3D WEBSITE',
        desc: `We are using JS as the programming language to create interactive, 3D graphics-based websites. This  involve using Java to develop the backend of the website, as well as to create the front-end user interface and any interactive elements.`,
        list: ['UI/UX', '3D Animation', 'Website Creation']
    },
    {
        id: 6,
        title: 'NFT',
        desc: `We offer NFT development services to help businesses and individuals create and manage their own NFTs. This involve designing and implementing smart contracts to manage the ownership and transfer of the NFTs, as well as developing the infrastructure to support the sale and distribution of the NFTs.`,
        list: ['2D/3D NFT Art', 'NFT Generation', 'Smart Contract', 'Ecosystem  Strategy ']
    },
]

export const stack = [
    {
        stack: 'Solidity',
        versions: 'ERC721, ERC1155, ERC20'
    },
    {
        stack: 'Rust',
        versions: 'Metaplex Integrations'
    },
    {
        stack: 'Adobe Photoshop Script',
        versions: ''
    },
    {
        stack: 'Python',
        versions: 'Pymax'
    },
    {
        stack: 'PHP',
        versions: 'Laravel Symphony'
    },
    {
        stack: 'SQL, NoSQL',
        versions: ''
    },
    {
        stack: 'Apache 2, NGINX',
        versions: ''
    },
    {
        stack: 'Javascript',
        versions: 'React.js, Node.js, THREE.js, TypeScript'
    },
    {
        stack: 'Web 3',
        versions: 'ethers.js, web3.js, react-web3'
    },
    {
        stack: 'HTML / CSS',
        versions: 'HTML5 Canvas, CSS3, CSS Grid, Flexbox, Bootstrap, Tailwind, Material UI'
    },
]

export const artStack = [
    {
        stack: '2D Art',
        versions: ''
    },
    {
        stack: '3D Modeling',
        versions: ''
    },
    {
        stack: 'NFT Generation',
        versions: ''
    },
]


export default team