import { imageTestominials1, imageTestominials2, imageTestominials3, imageTestominials4, imageTestominials5, imageTestominials6 } from "../assets";

const testimonials = [
    {
        name: 'Al Mark Radan',
        img: imageTestominials1,
        title: 'Generating 2D 10,000 NFTs for Opensea + Smart Contract',
        rating: 5,
        position: 'co-founder',
        description: '"GSD is the best. Great job creating on time, actually in my case, before time. Art is so great that we sold out back to back 2 collections. check it out astrobabies.io Team communication and response time are perfect. I Highly recommend them."',
        date: 'Dec 27, 2021 - Feb 4, 2022'
    },
    {
        name: 'Noah',
        img: imageTestominials2,
        title: 'NFT Designer',
        rating: 5,
        position: 'co-founder',
        description: '"Amazing work by Davit and his team at GSD Club on our NFT Project. I could not have asked for a better art studio to work on it. Thanks a lot, and I will definitely work with them again in the future."',
        date: 'Dec 27, 2021 - Feb 4, 2022',
    },
    {
        name: 'GRANNY BRUNY',
        img: imageTestominials3,
        title: 'NFT 3D character Design and direct mint site',
        rating: 5,
        position: 'co-founder',
        description: '"GSD is the best. Very nice Art, sold out our collection. I highly recommend them!"',
        date: 'Oct 15, 2021 - Dec 24, 2021',
    },
    {
        name: 'Jessica Brodkin',
        img: imageTestominials4,
        title: 'Generation of 10,000 NFT 3D collection + minting on the website and deploying to Opensea using solidity',
        rating: 5,
        position: 'co-founder',
        description: '"Amazing work! A very responsive, transparent, and genuine team that really cares about the success of our project. The NFT space is full of a lot of shady people, and these people are legit, honest, and try hard. The Art is really out of this world and made a splash on the NFT scene. They are aware of market trends and were very flexible with all of our requests and changes. We really loved working with GSD! Highly recommended!"',
        date: 'Sep 5, 2021 - Nov 7, 2021',
    },
    {
        name: 'Jason Buzi',
        img: imageTestominials5,
        title: 'Generation of 10,000 NFT 3D collection + minting on the website and deploying to Opensea using solidity',
        rating: 5,
        position: 'founder',
        description: '"GSD did an excellent job creating my NFT collection and supporting website and smart contract. My project was delayed due to problems on my end, and they patiently worked with me through this time and did everything they could to help me have a successful launch. Highly recommend this team!"',
        date: 'Sep 5, 2021 - Nov 7, 2021',
    },
    {
        name: 'Leo Bentovim',
        img: imageTestominials6,
        title: 'Generation of 10,000 NFT 3D collection + minting on the website and deploying to Opensea using solidity',
        rating: 5,
        position: 'founder',
        description: '"GSD and Mike were complete professionals who absolutely nailed this project. They helped us create a 10k NFT collection, mint them on the Blockchain, write the smart contract, etc. They absolutely crushed the artwork. It came out way better than we could have imagined. They have been so helpful along the way and really just a pleasure to work with. We had a lot of changes and special requests, and they were super flexible. I would strongly recommend them. I hope to continue using them for new projects. Thank you so much, Mike!!"',
        date: 'Sep 5, 2021 - Nov 7, 2021',
    },
];


export default testimonials