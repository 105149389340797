import { useEffect } from "react";
import { useLocation } from "react-router";
import { CSSTransition } from "react-transition-group";
import { TransitionGroup } from "react-transition-group";
import Footer from "../components/Footer";
import Header from "../components/Header";
import GoblinBanner from "../components/GoblinBanner";

const Layout = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
        if (!location.hash) {
            window.scrollTo(0, 0)
        }
    }, [location.key])

    return (
        <div>
            <GoblinBanner />
            <Header />
            <TransitionGroup>
                <CSSTransition
                    classNames='fade'
                    timeout={600}
                    key={location.key}
                >
                    <main>
                        {children}
                    </main>
                </CSSTransition>
            </TransitionGroup>
            <Footer />
        </div>
    )
}

export default Layout;