import cn from 'classnames'
import styles from './index.module.scss'


const Button = ({ link, href, children, className, ...props }) => {
    return (
        link ? (
            <a href={href} className={cn(styles['btn'], className)} {...props}>
                {children}
            </a>
        ) :
            <button className={cn(styles['btn'], className)} {...props}>
                {children}
            </button>
    )
}

export default Button;