import cn from "classnames";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from 'swiper/react';
import testimonials from '../../utils/testimonials';
import TestimonialCard from "../TestimonialCard";
import styles from './testominals.module.scss';



const Testominals = () => {
    return (
        <Swiper
            slidesPerView={1}
            navigation={true}
            className="slider-testominals"
            autoHeight={true}
            breakpoints={{
                992: {
                  slidesPerView: 2,
                },
            }}
        >
            {testimonials
                .map(({ name, position, description, title, rating, date, img }, index) => (
                    <SwiperSlide key={index}>
                        <TestimonialCard
                            key={`${name}_${index}`}
                            name={name}
                            title={title}
                            position={position}
                            description={description}
                            classNames={styles.testimonial_card}
                            rating={rating}
                            date={date}
                            img={img}
                        />
                    </SwiperSlide>
                ))}
        </Swiper>
    )
}

export default Testominals;